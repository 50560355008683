<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="1000px">
        <div class="edit-title-box">
            <div class="edit-title-box-left">模块添加：</div>
            <div class="edit-title-box-right">
                <el-button :key="item.lx" v-for="item in moKuaiTitleData" size="small" v-if="item.isShow" @click="moKuaiClick(item.lx)">{{item.mc}}</el-button>
            </div>
        </div>
        <div class="edit-content">
            <!--小票模块设置-->
            <div class="edit-content-left">
                <div class="forDiv" v-for="(item,index) in form.xiaoPiaoMoKuais">
                    <div class="edit-content-left-left">
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.DM.key">
                            <div style="font-size: 20px">XXX小店</div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.BT.key">
                            <div style="font-size: 18px">收银小票</div>
                            <div class="text-left">订单类型：收款订单</div>
                            <div class="text-left">订单号：000000000000000000</div>
                            <div class="text-left">订单时间：2019-01-01</div>
                            <div class="text-left">收银：收银员XXX</div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.SPXX.key">
                            <div class="star-line">
                                **********************************************************************************
                            </div>
                            <div class="goods-title">
                                <div class="text-left">名称</div>
                                <div class="text-right">单价</div>
                                <div class="text-right">数量</div>
                                <div class="text-right">小计</div>
                            </div>
                            <div v-for="(sp,index) in shangPinData" class="goods-title">
                                <div class="text-left">
                                    {{sp.name}}
                                    <div>(规格XX)</div>
                                </div>
                                <div class="text-right">{{sp.dj}}</div>
                                <div class="text-right">{{sp.sl}}</div>
                                <div class="text-right">{{sp.xj}}</div>
                            </div>
                            <div class="star-line">
                                **********************************************************************************
                            </div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.KHXX.key">
                            <div class="text-left">会员名称：会员XXX</div>
                            <div class="text-left">会员号码：137XXXXXXXX</div>
                            <div class="text-left">余额：188</div>
                            <div class="text-left">积分：234</div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.ZFXX.key">
                            <div class="text-left">支付时间：2019-01-11</div>
                            <div class="text-left">
                                <div>订单号：20190111141227154718004465</div>
                                <img src="@/image/szgl/tiao-xing-ma.png"/>
                            </div>
                            <div class="text-left">应收金额：33.50</div>
                            <div class="text-left">实收金额：50.00</div>
                            <div class="text-left">支付方式：现金</div>
                            <div class="text-left">优惠金额：0.00</div>
                            <div class="text-left">抹零金额：0.00</div>
                            <div class="text-left">找零金额：16.50</div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.HYCZ.key">
                            <div class="text-left">充值时间：2019-01-11</div>
                            <div class="text-left">订单号：20190115103710154751004687</div>
                            <div class="text-left">充值实付：100.00</div>
                            <div class="text-left">充值获得：120.00</div>
                            <div class="text-left">支付方式：现金支付</div>
                            <div class="text-left">操作人员：收银员张三</div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.FZX.key">
                            <el-divider></el-divider>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.DPTG.key">
                            <div class="text-left">店铺推广内容</div>
                            <div style="width: 128px;height: 128px;margin:auto">
                                <img src="@/image/szgl/er-wei-ma.png"/>
                            </div>
                        </div>
                        <div class="text-box" v-if="item.moKuaiLX===dictXiaoPiaoMoKuai.WB.key">
                            <div style="font-size: 20px;">谢谢惠顾，欢迎再次光临！</div>
                        </div>

                    </div>
                    <div class="edit-content-left-right">
                        <el-button :disabled="!isDeleteMoKuai" @click="deleteMoKuai(item.sort)" type="text" size="small"
                                   circle><i style="font-size: 20px" class="el-icon-delete"></i></el-button>
                    </div>
                </div>
            </div>
            <!--小票基础信息设置-->
            <div class="edit-content-right">
                <el-form ref="form" :model="form" :rules="rules" class="x-edit">
                    <el-form-item label="小票名称" prop="mingCheng" class="line">
                        <el-input v-model="form.mingCheng" size="small" maxlength="50" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="安卓小票宽度" prop="piaoZhiKuanDu" class="line">
                        <div>
                            <x-radio dict-type="T_PIAO_ZHI_WIDTH" v-model="form.piaoZhiKuanDu"></x-radio>
                            <div class="ti-shi">该设置仅在安卓USB打印生效</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="PC小票宽度" prop="pcKuanDu" class="line">
                        <div>
                            <el-input v-model="form.pcKuanDu" size="small" maxlength="36" show-word-limit/>
                            <div class="ti-shi">58mm纸建议填180,80mm纸建议填248该设置仅在PC端USB打印生效</div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/szgl/XiaoPiaoSheZhi";
    import XEditBase from "@/components/x/XEditBase";
    import {DICT_TYPE_XIAO_PIAO, DICT_USE, DICT_TYPE_XIAO_PIAO_MO_KUAI} from "@/util/constant"
    import {ruleBuilder} from '@/util/validate';
    import {deepCopy, deepMerge} from "@/util/objects"

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                xiaoPiaoLX: [], // 小票类型
                mingCheng: [required()], // 名称
                pcKuanDu: [required()], // PC宽度
                piaoZhiKuanDu: [required()], // 票纸宽度
                zhuangTai: [], // 状态
            }
            // this.titlePrefix = '小票设置';
            this.defaultForm = {
                id: null,
                xiaoPiaoLX: "", // 小票类型
                mingCheng: "", // 名称
                pcKuanDu: "180", // PC宽度
                piaoZhiKuanDu: "", // 票纸宽度
                zhuangTai: DICT_USE.DISABLE.key,// 状态
                xiaoPiaoMoKuais: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                xiaoPiaoLX: '',
                dictXiaoPiaoMoKuai: DICT_TYPE_XIAO_PIAO_MO_KUAI,
                shangPinData: [
                    {mc: "商品1", dj: 10, sl: 1, xj: 10},
                    {mc: "商品2", dj: 5, sl: 3, xj: 15},
                    {mc: "商品3", dj: 8.5, sl: 1, xj: 8.5},
                ],
                titlePrefix:"小票设置",
                moKuaiTitleData:[
                    {mc:"店名区", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.DM.key, isShow:true},
                    {mc:"标题", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.BT.key, isShow:this.isSyXp},
                    {mc:"商品信息", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.SPXX.key, isShow:this.isSyXp},
                    {mc:"客户信息", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.KHXX.key, isShow:true},
                    {mc:"支付信息", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.ZFXX.key, isShow:this.isSyXp},
                    {mc:"会员充值", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.HYCZ.key, isShow:!this.isSyXp},
                    {mc:"辅助线", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.FZX.key, isShow:true},
                    {mc:"店铺推广", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.DPTG.key, isShow:true},
                    {mc:"文本", lx:DICT_TYPE_XIAO_PIAO_MO_KUAI.WB.key, isShow:true},
                ],
                xiaoPiaoMoKuaisModel: {
                    touBuZiTi: "20", // 头部字体
                    neiRongZiTi: "14", // 内容字体
                    sort: 0, // 排序
                    dingDanHao: "", // 订单号
                    dingDanLX: "", // 订单类型
                    dingDanSJ: "", // 订单时间
                    shouYinYuan: "", // 收银员
                    biaoTiNeiRong: "", // 标题/内容
                    erWeiMaNeiRong: "", // 二维码内容
                    zhiFuSJ: "", // 支付时间
                    dingDanTiaoMa: "", // 订单条码
                    yingShouJinE: "", // 应收金额
                    shiShouJinE: "", // 实收金额
                    zhaoLingJinE: "", // 找零金额
                    moLingJinE: "", // 抹零金额
                    zhiFuFS: "", // 支付方式
                    youHuiJuan: "", // 优惠卷
                    huiYuanMC: "", // 会员名称
                    huiYuanHM: "", // 会员号码
                    huiYuanYuE: "", // 会员余额
                    jiFen: "", // 积分
                    moKuaiLX: "", // 模块类型
                    chongZhiZhiFu: "", // 充值支付
                    chongZhiHuoDe: "", // 充值获得
                }
            }
        },
        computed: {
            isSyXp() {
                return this.xiaoPiaoLX === DICT_TYPE_XIAO_PIAO.SYXP.key
            },
            isDeleteMoKuai() {
                return this.form.xiaoPiaoMoKuais.length > 1
            }
        },
        methods: {
            open() {
                this.titlePrefix = this.isSyXp ? "收银小票" : "充值小票"
                //验证是编辑还是添加并处理
                if (this.isUpdate) {
                    return this.getUpdateService(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        //清空校验
                        this._clearValidate();
                        return response;
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    this.form.xiaoPiaoLX = this.xiaoPiaoLX
                    this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.DM.key)
                    if (this.isSyXp) {
                        this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.BT.key)
                        this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.SPXX.key)
                        this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.ZFXX.key)
                    } else {
                        this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.HYCZ.key)
                    }
                    this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.KHXX.key)
                    this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.FZX.key)
                    this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.DPTG.key)
                    this.moKuaiClick(DICT_TYPE_XIAO_PIAO_MO_KUAI.WB.key)
                    //清空校验
                    this._clearValidate();
                    return Promise.resolve({code: 200});
                }
            },
            deleteMoKuai(index) {
                this.form.xiaoPiaoMoKuais.splice(index, 1)
                let sort = 0;
                this.form.xiaoPiaoMoKuais.forEach(item => {
                    item.sort = sort
                    sort++;
                })
            },
            moKuaiClick(val) {
                let moKuai = deepCopy(this.xiaoPiaoMoKuaisModel)
                Object.keys(moKuai).forEach(key => {
                    if (!moKuai[key]) {
                        moKuai[key] = DICT_USE.DISABLE.key
                    }
                })
                let bigSize = "20";
                //根据模块类型设置对象中的值
                switch (val) {
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.DM.key:
                        moKuai.neiRongZiTi = bigSize
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.DM.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.BT.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.BT.key
                        moKuai.dingDanLX = DICT_USE.ENABLE.key
                        moKuai.dingDanHao = DICT_USE.ENABLE.key
                        moKuai.dingDanSJ = DICT_USE.ENABLE.key
                        moKuai.shouYinYuan = DICT_USE.ENABLE.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.SPXX.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.SPXX.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.KHXX.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.KHXX.key
                        moKuai.huiYuanMC = DICT_USE.ENABLE.key
                        moKuai.huiYuanHM = DICT_USE.ENABLE.key
                        moKuai.huiYuanYuE = DICT_USE.ENABLE.key
                        moKuai.jiFen = DICT_USE.ENABLE.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.ZFXX.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.ZFXX.key
                        moKuai.zhiFuSJ = DICT_USE.ENABLE.key
                        moKuai.dingDanHao = DICT_USE.ENABLE.key
                        moKuai.dingDanTiaoMa = DICT_USE.ENABLE.key
                        moKuai.yingShouJinE = DICT_USE.ENABLE.key
                        moKuai.shiShouJinE = DICT_USE.ENABLE.key
                        moKuai.zhaoLingJinE = DICT_USE.ENABLE.key
                        moKuai.moLingJinE = DICT_USE.ENABLE.key
                        moKuai.zhiFuFS = DICT_USE.ENABLE.key
                        moKuai.youHuiJuan = DICT_USE.ENABLE.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.HYCZ.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.HYCZ.key
                        moKuai.zhiFuSJ = DICT_USE.ENABLE.key
                        moKuai.dingDanHao = DICT_USE.ENABLE.key
                        moKuai.chongZhiZhiFu = DICT_USE.ENABLE.key
                        moKuai.chongZhiHuoDe = DICT_USE.ENABLE.key
                        moKuai.zhiFuFS = DICT_USE.ENABLE.key
                        moKuai.shouYinYuan = DICT_USE.ENABLE.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.FZX.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.FZX.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.DPTG.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.DPTG.key
                        break;
                    case DICT_TYPE_XIAO_PIAO_MO_KUAI.WB.key:
                        moKuai.moKuaiLX = DICT_TYPE_XIAO_PIAO_MO_KUAI.WB.key
                        moKuai.neiRongZiTi = bigSize
                        break;
                }
                moKuai.sort = this.form.xiaoPiaoMoKuais.length
                this.form.xiaoPiaoMoKuais.push(moKuai)
            }
        }
    }
</script>

<style scoped>
    .edit-title-box {
        margin-bottom: 20px;
    }

    .edit-title-box-left {
        width: 10%;
        position: relative;
        display: inline-block;
        text-align: left;
    }

    .edit-title-box-right {
        width: 90%;
        position: relative;
        display: inline-block;
    }

    .edit-content {
        display: flex;
    }

    .edit-content-left {
        width: 45%;
        position: relative;
        display: inline-block;
    }

    .edit-content-right {
        width: 45%;
        position: relative;
        display: inline-block;
    }

    .forDiv {
        display: flex;
    }

    .edit-content-left-left {
        width: 90%;
        height: 100%;
        position: relative;
        display: inline-block;
        border-top: 1px dashed #ebebeb;
        border-bottom: 1px dashed #ebebeb;
        padding: 0 20px;
    }

    .edit-content-left-right {
        width: 10%;
        position: relative;
        display: inline-block;
    }

    .ti-shi {
        font-size: 12px;
        color: #909399;
    }

    .text-box {
        width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
        text-align: center;
        /*商品信息中的内容*/
        height: auto;
        line-height: normal;
        overflow-x: hidden;
        box-shadow: 0 0px 0px #ccc;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .star-line {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
    }

    .goods-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
</style>
