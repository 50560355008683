import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/xiao-piao-she-zhi/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/xiao-piao-she-zhi/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/xiao-piao-she-zhi/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/xiao-piao-she-zhi/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/xiao-piao-she-zhi/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/xiao-piao-she-zhi/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/xiao-piao-she-zhi/delete-batch',
        method: 'post',
        data
    })
}

//启用/禁用
export function use(data) {
    return request({
        url: '/xiao-piao-she-zhi/use',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'xiao-piao-she-zhi-list',
    component: () => import('@/view/szgl/xpsz/XiaoPiaoSheZhiList'),
    name: 'XiaoPiaoSheZhiList',
    meta: {title: '小票设置', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"小票设置基础列表","methodUrl":"/xiao-piao-she-zhi/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"小票设置添加保存","methodUrl":"/xiao-piao-she-zhi/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"小票设置获取编辑数据","methodUrl":"/xiao-piao-she-zhi/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"小票设置获取详情数据","methodUrl":"/xiao-piao-she-zhi/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"小票设置编辑保存","methodUrl":"/xiao-piao-she-zhi/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"小票设置删除","methodUrl":"/xiao-piao-she-zhi/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"小票设置批量删除","methodUrl":"/xiao-piao-she-zhi/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
