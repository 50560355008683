<template>
    <div class="x-page-container" ref="resize">
        <div class="x-page-search">
            <x-search-item label="小票名称" >
                <el-input v-model="search.mingCheng" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd(typeXiaoPiao.SYXP.key)">添加收银小票</el-button>
            <el-button type="success" size="mini" round @click="handleAdd(typeXiaoPiao.CZXP.key)">添加充值小票</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column  label="小票类型">
                <x-dict-show slot-scope="{row}" :code="row.xiaoPiaoLX" dictType="T_XIAO_PIAO" />
            </el-table-column>
            <el-table-column prop="mingCheng" label="名称"/>
            <el-table-column prop="pcKuanDu" label="PC宽度"/>
            <el-table-column prop="piaoZhiKuanDu" label="票纸宽度"/>
            <el-table-column prop="zhuangTai" label="状态">
                <template slot-scope="scope">
                    <x-switch v-model="scope.row.zhuangTai" size="small" dictType="T_USE"
                              @change="zhuangTaiChange($event,scope.row.id)"/>
                </template>
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleUpdate(row)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/szgl/XiaoPiaoSheZhi";
    import XTableBase from "@/components/x/XTableBase";
    import {DICT_TYPE_XIAO_PIAO} from "@/util/constant"
    import Edit from "@/view/szgl/xpsz/XiaoPiaoSheZhiEdit";

    export default {
        name: "XiaoPiaoSheZhiList",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    mingCheng:""
                },
                typeXiaoPiao: DICT_TYPE_XIAO_PIAO
            }
        },
        methods: {
            zhuangTaiChange(val, id) {
                this.service.use({id: id, zhuangTai: val}).then((res) => {
                    this.$message.success(res.msg);
                    this.refresh()
                })
            },
            handleUpdate(row) {
                this.editor.isUpdate = true;
                this.editor.id = row.id;
                this.editor.visible = true;
                this.$refs.edit.xiaoPiaoLX = row.xiaoPiaoLX
            },
            handleAdd(val) {
                this.editor.isUpdate = false;
                this.editor.id = null;
                this.editor.visible = true;
                this.$refs.edit.xiaoPiaoLX = val
            },
        }
    }
</script>

<style scoped>
</style>
